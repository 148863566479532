import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import Button from '@atlaskit/button';
import iBomItem from '../../../../../../types/product/iBomItem';
import iProduct from '../../../../../../types/product/iProduct';
import iParamTypes from '../../../../../../types/iParamTypes';
import iBomItemAttribute from '../../../../../../types/product/iBomItemAttribute';
import { iActionTypes } from '../../../../../../types/iActionTypes';
import { iBomItemAttributeValueParams } from './ProdBom.type';
import BomListTable from './BomListTable';
import CopyFromModal from './CopyFromModal';
import AsyncSearch from '../../../../../../shared/asyncSearch/AsyncSearch';
import CustomizeModal from '../../../../../../shared/modal/CustomizeModal';
import useListCrudHook from '../../../../../../shared/hooks/useListCrudHook/useListCrudHook';
import FlexSpaceBetweenContainer from '../../../../../../shared/job/jobDetails/styles/FlexSpaceBetweenContainer';
import { addToastForAPIResponse, apiErrorToast } from '../../../../../../shared/toast/Toast';
import { currencyFormat } from '../../../../../../services/UtilsService';
import { getProductListAsyncSearch } from '../../../../../../services/product/ProductService';
import {
  createBomAttributeValue,
  getBomItemAttributes,
  updateBomAttributeValue,
} from '../../../../../../services/BomAttributeService';
import { BoldFont, FlexContainer, ItalicizedFont } from '../../../../../../shared/styles/styles';
import { copyBomItems, createBOM, deleteBOM, getBOMs, updateBOM } from '../../../../../../services/BOMService';
import MathHelper from '../../../../../../services/MathHelper';
import { BOM_ATTRIBUTE_EXTRUDER_PERCENTAGE } from '../../../../../../shared/job/constants';

const TitleContainer = styled.div`
  min-width: 40%;
`;

const TableWrapper = styled.div``;

const AsyncSearchWrapper = styled.div`
  width: 25rem;
  min-width: 20rem;
`;

const CalculationWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding-right: 88px;

  font-weight: bold;
  font-size: 16px;
  gap: 1rem;
`;

const ProdBOM = ({ product, isDisabled }: { product?: iProduct; isDisabled?: boolean }) => {
  const { id } = useParams<iParamTypes>();
  const productId = product?.id || id;
  const [isCopyFromModalOpen, setIsCopyFromModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sourceProductId, setSourceProductId] = useState('');
  const [isRetypeValid, setIsRetypeValid] = useState(false);
  const [bomItemAttrCluster, setBomItemAttrCluster] = useState<Array<iBomItemAttribute>>([]);

  const { state, onDelete, onRefresh } = useListCrudHook<iBomItem>({
    getFn: getBOMs,
    createFn: createBOM,
    updateFn: updateBOM,
    deleteFn: deleteBOM,
    filter: `productId:${productId}`,
  });

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const bomAttributes = await getBomItemAttributes();
        if (!mounted) return;
        setBomItemAttrCluster(bomAttributes);
        setIsLoading(false);
      } catch (e) {
        if (!mounted) return;
        addToastForAPIResponse('error');
        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  const onClickCopyFrom = () => {
    setIsCopyFromModalOpen(true);
  };

  const onConfirmCopyFrom = async () => {
    try {
      await copyBomItems({
        sourceProductId,
        targetProductId: productId,
      });
      onRefresh();
      addToastForAPIResponse('success');
      setIsCopyFromModalOpen(false);
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const getSourceProductId = (payload: string) => {
    setSourceProductId(payload);
  };

  const onCheckRetypeAnswer = (isRightAnswer: boolean) => {
    setIsRetypeValid(isRightAnswer);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSelect = async (payload: string, actionMeta: iActionTypes) => {
    if (!payload) return;
    try {
      const materialId = payload.split(':')[0];
      // create a new bomItem for target product
      const created = await createBOM({
        productId,
        materialId,
        materialQty: 1,
        sortOrder: 1,
      });
      onRefresh();
      setTimeout(() => document.getElementById(`${created?.id}:materialQty`)?.focus(), 200);
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onConfirmBomAttributeValue = async (params: iBomItemAttributeValueParams) => {
    try {
      //  eslint-disable-next-line
      const newAttrValue = params.bomItemAttributeValueId
        ? await updateBomAttributeValue(params.bomItemAttributeValueId, {
            value: params.value,
          })
        : await createBomAttributeValue({
            bomItemId: params.bomItemId,
            bomItemAttributeId: params.bomItemAttributeId,
            value: params.value,
          });

      onRefresh();
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const onConfirmBomItem = async (name: string, newValue: string) => {
    try {
      await updateBOM(name.split(':')[0], {
        [`${name.split(':')[1]}`]: newValue,
      });
      onRefresh();
      addToastForAPIResponse('success');
    } catch (e) {
      apiErrorToast(e);
    }
  };

  const calculateAverageCost = (boms: iBomItem[]) => {
    const hoopAttrs = bomItemAttrCluster.filter(
      bomItemAttr => bomItemAttr.name.toUpperCase() === BOM_ATTRIBUTE_EXTRUDER_PERCENTAGE,
    );
    return boms.reduce((acc: number, bom: iBomItem) => {
      const lineSum = MathHelper.mul(bom.material.averageCost, bom.materialQty);
      let extruderPercentage = 1;
      if (hoopAttrs.length > 0) {
        const hooperAttributes = bom.bomItemAttributes.filter(bomItemAttr => bomItemAttr.id === hoopAttrs[0].id);
        if (hooperAttributes.length > 0) {
          extruderPercentage = Number(hooperAttributes[0].bomItemAttributeValues.value) || 1;
        }
      }
      return MathHelper.add(MathHelper.mul(lineSum, extruderPercentage), acc);
    }, 0);
  };

  const calculateTotalAmt = (boms: iBomItem[]) => {
    return boms
      .filter(bom => {
        return bom.material?.measurementId === product?.measurementId;
      })
      .reduce((acc: number, bom: iBomItem) => {
        return MathHelper.add(acc, bom.materialQty || 0);
      }, 0);
  };

  return (
    <>
      <FlexContainer className={'space-between space-above space-below'}>
        <TitleContainer>
          <h4>To make 1 {product?.measurement?.shortName || ''} of this product</h4>
        </TitleContainer>

        <FlexSpaceBetweenContainer>
          <div>
            Material: <BoldFont>{state.data.length}</BoldFont>
          </div>
          <div>
            <span style={{ marginRight: '5px' }}>Estimated Cost</span>
            <BoldFont>{state.data && currencyFormat(calculateAverageCost(state.data))}</BoldFont>/{' '}
            <ItalicizedFont>{product?.measurement?.shortName || ''}</ItalicizedFont>
          </div>
          {isDisabled ? null : (
            <Button
              className={'popup-item'}
              onClick={onClickCopyFrom}
              testId={'product-bom-copyFrom-button'}
              iconBefore={<DocumentsIcon label={'copyFrom'} />}
            >
              Copy From
            </Button>
          )}
        </FlexSpaceBetweenContainer>
      </FlexContainer>

      {isCopyFromModalOpen && (
        <CustomizeModal
          isOpen={isCopyFromModalOpen}
          onConfirm={onConfirmCopyFrom}
          onCancel={() => setIsCopyFromModalOpen(false)}
          isDisabled={!sourceProductId || !isRetypeValid}
          isConfirming={state.isConfirming}
          modalBody={
            <CopyFromModal
              bomItemAttrCluster={bomItemAttrCluster}
              getSourceProductId={getSourceProductId}
              onCheckRetypeAnswer={onCheckRetypeAnswer}
            />
          }
          modalHeading={'Copy BOM'}
          confirmBtnName={'Confirm'}
        />
      )}

      <TableWrapper>
        <BomListTable
          data={state.data}
          onDelete={onDelete}
          onConfirmBomItem={onConfirmBomItem}
          onConfirmBomAttributeValue={onConfirmBomAttributeValue}
          isLoading={state.isLoading || isLoading}
          bomItemAttrCluster={bomItemAttrCluster}
          canDelete={!isDisabled}
          canUpdate={!isDisabled}
        />
        <FlexContainer className={'space-between'}>
          <AsyncSearchWrapper>
            <AsyncSearch
              onSelect={onSelect}
              promiseFn={(keyword: string) =>
                getProductListAsyncSearch({
                  like: `productCode:${keyword},name:${keyword}`,
                })
              }
              optionLabel={['productCode', 'name']}
              searchBarPlaceholder={'Search product code or name to add as BOM...'}
              isDisabled={isDisabled}
            />
          </AsyncSearchWrapper>
          <CalculationWrapper>
            <div>
              <span>Amt: </span>
              <span>
                {state.data && calculateTotalAmt(state.data)} {product?.measurement?.shortName || ''}
              </span>
            </div>
            <div>
              <span>Total: </span>
              <span>{state.data && currencyFormat(calculateAverageCost(state.data))}</span>
            </div>
          </CalculationWrapper>
        </FlexContainer>
      </TableWrapper>
    </>
  );
};

export default ProdBOM;
